<template>
  <header
    class="pin-t pin-x hidden md:flex header text-black"
    :class="{
      'header-transparent': isTransparent,
      'bg-fcfcfc95': isHeaderRaised && !$route.meta.theme,
      'bg-333': $route.meta.theme === 'dark',
      'text-black': $route.meta.theme === 'dark'
    }"
  >
    <!-- xxx -->
    <!-- :class="{
      'header-transparent': isTransparent ,
      'bg-fcfcfc95': isHeaderRaised && !$route.meta.theme,
      'bg-333': $route.meta.theme === 'dark',
      'text-on-dark': $route.meta.theme === 'dark'
    }" -->

    <!-- origin -->
    <!-- 
    <header
    :class="{
      [$style.header]: true,
      'bg-fcfcfccc': isHeaderRaised && !$route.meta.theme,
      'bg-black': isHeaderRaised && $route.meta.theme === 'dark',
      'text-on-dark': $route.meta.theme === 'dark'
    }"
    class="pin-t pin-x hidden md:flex"
  >
  -->

    <nuxt-link
      to="/"
      class="flex-none mx-auto flex items-center px-5"
    >
      <img
        v-if="$route.meta.theme === 'dark'"
        src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/logotype-unipapa-dark.svg"
        alt="Unipapa"
        height="24"
        class="flex-none h-6"
      >  
      <img
        v-else
        src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/logotype-unipapa.svg"
        alt="Unipapa"
        height="24"
        class="flex-none h-6"
      >
    </nuxt-link>

    <div class="flex-auto relative">
      <nav
        v-if="$route.meta.isHideLink !== true"
        class="flex h-full"
        aria-label="主要連結"
      >
        <nuxt-link
          to="/product/listing/3"
          class="flex-none flex items-center px-5 haohao-2"
          :class="{ 'text-999': $route.fullPath == '/product/listing/3','text-on-dark': $route.meta.theme === 'dark' }"
        >
          電器製品
        </nuxt-link>
        
        <!-- <nuxt-link
          to="/product/listing/1"
          class="flex-none flex items-center px-5 haohao-2 "
          :class="{ 'text-999': $route.fullPath == '/product/listing/1','text-on-dark': $route.meta.theme === 'dark' }"
        >
          居家清潔
        </nuxt-link> -->
        <nuxt-link
          to="/product/listing/2"
          class="flex-none flex items-center px-5 haohao-2"
          :class="{ 'text-999': $route.fullPath == '/product/listing/2','text-on-dark': $route.meta.theme === 'dark' }"
        >
          防蚊防蟲
        </nuxt-link>

        <nuxt-link
          to="/product/listing/4"
          class="flex-none flex items-center px-5 haohao-2"
          :class="{ 'text-999': $route.fullPath == '/product/listing/4','text-on-dark': $route.meta.theme === 'dark' }"
        >
          <!-- 雜貨日用品 -->
          生活用品
        </nuxt-link>

        <!-- <nuxt-link
          to="/brands"
          class="flex-none flex items-center px-5 haohao-2"
          :class="{ 'text-999': $route.fullPath == '/brands','text-on-dark': $route.meta.theme === 'dark' }"
        >
          選物推薦
        </nuxt-link> -->
        <!--         
        <a
          href="https://www.sheeper.com.tw"
          target="_blank"
          class="flex-none flex items-center px-5 haohao-2"
          :class="{ 'text-999': $route.fullPath == '/brands','text-on-dark': $route.meta.theme === 'dark' }"
        >
          <img
            src="https://d1hes8uqcp6qpi.cloudfront.net/img/logo2.svg"
            class="h-4"
          >
        </a>
         -->
        <!-- <nuxt-link
          to="/article/listing"
          class="flex-none flex items-center px-6 "
        >
          有理讀物
        </nuxt-link> -->
        <label
          for="search2"
          class="flex-none ml-auto flex items-center px-4 pointer"
          @click="$store.commit('site/showSearchModal', true)"
        >
          <span class="icon-unipapa icon-search text-26" />
        </label>
      </nav>
      <!-- <TheSearchBar id="search" /> -->
    </div>

    <nuxt-link
      to="/cart"
      aria-label="購物車"
      class="flex-none flex items-center px-4 "
    >
      <!-- haohao -->
      <!-- <div class="text-26 relative">
        <img
          src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-cart-4.svg"
        >
        <no-ssr>
          <div
            :class="{
              'bg-black': !$route.meta.theme,
              'text-on-dark': !$route.meta.theme,
              'bg-white': $route.meta.theme === 'dark',
              'text-on-light': $route.meta.theme === 'dark'
            }"
            class="absolute -mr-3 -mt-3 w-4 h-4 flex items-center justify-center text-12 font-700 tracking-normal leading-none rounded-full select-none"
            style="right: 3px; top: 15px;"
          >
            {{ $store.getters['cart/totalQuantity'] }}
          </div>
        </no-ssr>
      </div> -->

      <!-- origin -->
      <!-- class="absolute -mr-3 -mt-3 w-5 h-5 flex items-center justify-center text-12 font-700 tracking-normal leading-none rounded-full select-none" -->

      <div class="icon-unipapa icon-cart text-26 relative">
        <no-ssr>
          <div
            :class="{
              'bg-black': !$route.meta.theme,
              'text-on-dark': !$route.meta.theme,
              'bg-white': $route.meta.theme === 'dark',
              'text-on-light': $route.meta.theme === 'dark'
            }"
            class="absolute w-5 h-5 flex items-center justify-center text-12 font-700 tracking-normal leading-none rounded-full select-none"
            style="right: -8px; top: -3px;"
          >
            {{ $store.getters['cart/totalQuantity'] }}
          </div>
        </no-ssr>
      </div>
    </nuxt-link>

    <div
      v-if="$store.state.user.isLogin"
      :class="$style.dropdown"
      class="relative"
    >
      <button
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        type="button"
        class="h-full px-4"
        @click="$store.commit('site/showProfileModal', true)"
      >
        <vue-avatar
          v-if="$store.state.user.user.avatar"
          :username="$store.state.user.user.name"
          :size="32"
          :src="$store.state.user.user.avatar | assetURL"
          class="mx-auto border-eee border-1"
        />
        <vue-avatar
          v-else
          :username="$store.state.user.user.name"
          :size="32"
          background-color="#000000"
          color="#FFFFFF"
          class="mx-auto border-eee border-1"
        />
      </button>
    </div>

    <!-- @click="$store.commit('site/showLoginModal', true)" -->
    <nuxt-link
      v-else
      to="/login"
      class="btn text-14 px-5 pointer"
    >
      登入
    </nuxt-link>
  </header>
</template>

<script>
// import TheSearchBar from './TheSearchBar.vue';
import scrollMonitor from 'scrollmonitor';

export default {
  // components: {
  //   TheSearchBar,
  // },

  data() {
    return {
      isHeaderRaised: false,
      // isTransparent: true,
      isTransparent: false,
    };
  },

  mounted() {
    this.$_scrollWatcher = scrollMonitor.create(60);
    // this.$_scrollWatcher = scrollMonitor.create(
    //   scrollMonitor.viewportHeight - 200
    // );
    // this.$_scrollWatcher = scrollMonitor.create(window.innerHeight);
    this.isHeaderRaised = !this.$_scrollWatcher.isInViewport;
    this.$_scrollWatcher.on('stateChange', () => {
      this.isHeaderRaised = !this.$_scrollWatcher.isInViewport;
      // this.isTransparent = this.$_scrollWatcher.isInViewport;
    });
  },

  beforeDestroy() {
    try {
      // this.$_scrollWatcher.destroy();
    } catch (e) {
      // yada
    }
  },
};
</script>

<style module>
/*
.dropdown-menu {
  top: 100%;
}

.dropdown:hover > .dropdown-menu {
  pointer-events: auto;
  opacity: 1;
}
*/
</style>
